
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as api0xwQxrRhIUMeta } from "C:/Users/nesto/Documents/Colors/colors-front/pages/api.vue?macro=true";
import { default as confirm_45emailHSPtkqmlAmMeta } from "C:/Users/nesto/Documents/Colors/colors-front/pages/confirm-email.vue?macro=true";
import { default as confirmqPdhhHl2zcMeta } from "C:/Users/nesto/Documents/Colors/colors-front/pages/confirm.vue?macro=true";
import { default as dashboardWnUvPT6NuFMeta } from "C:/Users/nesto/Documents/Colors/colors-front/pages/dashboard.vue?macro=true";
import { default as indexsXAejmM4hUMeta } from "C:/Users/nesto/Documents/Colors/colors-front/pages/index.vue?macro=true";
import { default as loginI7i1q1mXpqMeta } from "C:/Users/nesto/Documents/Colors/colors-front/pages/login.vue?macro=true";
import { default as pricingF6xyYkRe15Meta } from "C:/Users/nesto/Documents/Colors/colors-front/pages/pricing.vue?macro=true";
import { default as reset_45password7zGE0ULjeVMeta } from "C:/Users/nesto/Documents/Colors/colors-front/pages/reset-password.vue?macro=true";
export default [
  {
    name: "api",
    path: "/api",
    component: () => import("C:/Users/nesto/Documents/Colors/colors-front/pages/api.vue")
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    component: () => import("C:/Users/nesto/Documents/Colors/colors-front/pages/confirm-email.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("C:/Users/nesto/Documents/Colors/colors-front/pages/confirm.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("C:/Users/nesto/Documents/Colors/colors-front/pages/dashboard.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/Users/nesto/Documents/Colors/colors-front/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("C:/Users/nesto/Documents/Colors/colors-front/pages/login.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("C:/Users/nesto/Documents/Colors/colors-front/pages/pricing.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("C:/Users/nesto/Documents/Colors/colors-front/pages/reset-password.vue")
  }
]